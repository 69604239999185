export interface Account {
  id: number;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  name: string;
  description: string | null;
  balance: number;
  type: "user" | "event" | "product_ledger" | "income_ledger";
  pinSet: boolean;
  pin?: string;
  allowNegativeBalance: boolean;
  sepa: AccountSepa | null;
  lastUsed: string;
}

export interface DirectDebitData {
  directDebit: boolean;
  iban: string | null;
  bic: string | null;
  mandateId: string | null;
  signDate: string | null;
}

export type DirectDebitAccount = Account & DirectDebitData;

export interface AccountSepa {
  amount: number;
  exec_date: string;
}

export enum TransactionStatus {
  INITIALIZING = "initializing",
  PENDING = "pending",
  COMPLETED = "completed",
  EXPIRED = "expired",
  DELETED = "deleted",
  CANCELLED = "cancelled",
}

export enum TransactionFlowType {
  CARD_PAYMENT = "card_payment",
  CARD_ADMIN_PAYMENT = "card_admin_payment",
  CASH_PAYMENT = "cash_payment",
  SEPA_PAYMENT = "sepa_payment",
  IDEAL_PAYMENT = "ideal_payment",
  ACCOUNT_PAYMENT = "account_payment",
  TRANSFER_PAYMENT = "transfer_payment",
  OTHER_PAYMENT = "other_payment",
  OTHER_ADMIN_PAYMENT = "other_admin_payment",
}

export interface Transaction {
  id: number;
  createdAt: Date;
  deletedAt: Date | null;
  deleted: boolean;
  status: TransactionStatus;
  flow: TransactionFlowType;
  orders: Order[];
  reason: string | null;
}

export interface Order {
  id: number;
  product: {
    id: number;
    name: string;
  } | null;
  account: {
    id: number;
    name: string;
    ledgerAccount: boolean;
  };
  method: "cash" | "card" | "account" | "transfer" | "ideal" | "sepa" | "other";
  count: number;
  price: number;
  register: "default" | "pending";
  flow_type:
    | "none_to_completed"
    | "none_to_pending"
    | "pending_to_completed"
    | "pending_to_deleted"
    | "pending_to_expired"
    | "completed_to_deleted";
  type:
    | "INVALID"
    | "DIRECT_PAYMENT"
    | "ACCOUNT_TOP_UP_OR_ACCOUNT_PAYMENT"
    | "PRODUCT_PURCHASE";
  deleted: boolean;
}

export interface Product {
  id: number;
  price: number;
  name: string;
  deposit: boolean;
}

export class CartEntry {
  public product: Product;
  public count: number;

  constructor(product: Product, amount: number) {
    this.product = product;
    this.count = amount;
  }
}

export class Category {
  public products: Product[];
  public name: string;
  public color: number;

  constructor(name: string, color: number, products: Product[]) {
    this.name = name;
    this.color = color;
    this.products = products;
  }
}

export class User {
  public id: number;
  public username: string;
  public name: string;
  public isAdmin: boolean;
  public accountId: number | null = null;
  public viaductUserId: number | null = null;
}

export class Config {
  public viaductClientId: string;
  public user: User;
  public register: UserRegister;
  public requireSettingAccountPin = false;
  public requireTfa = false;
  public aprilFoolsEnabled = false;
  public posWrappedAvailable = false;
  public acceptedCurrentCondition: false;
}

export class UserRegister {
  public id: number;
  public name: string;
  public capabilities: string[];
  public categoryIds: number[];
}

export interface AccountRequest {
  id: number;
  user_id: number;
  createdAt: string;
  name: string;
  status: "pending" | "approved" | "declined";
}

export interface DateRangeQueryParam {
  start?: string;
  end?: string;
}

export interface CheckoutReason {
  name: string;
  pin?: string;
}

export interface SlideBarProduct {
  name: string;
  price: number;
  add_spacing: boolean;
}

export interface SlideBarTrend {
  name: string;
  sales: number;
  trend: number | null;
}
